body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gmnoprint {
  left: unset !important;
  right: 0px;
}

.gm-bundled-control-on-bottom {
  right: 5px !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255,255,255, 1); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, .5);
  border-radius: 10px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(245, 245, 245, .5);; 
}

